'use client'

import { useRouter } from 'next/navigation'
import { NextSeo } from 'next-seo'
import { ERROR } from '@/components/Error/consts'
import { META_TITLES, URL_PATH } from '@/consts'
import { SubPageHeader } from '@/v1/Header/SubPageHeader'

const height = 'calc(100vh - 48px)'
const REDIRECT_URL = {
  board: URL_PATH.Boards,
  board_update: URL_PATH.Boards,
  users_me_board: URL_PATH.MyCare,
  guides: URL_PATH.GuidesMainBreast,
  guides_colon: URL_PATH.GuidesMainColon,
  guides_lung: URL_PATH.GuidesMainLung,
  guides_thyroid: URL_PATH.GuidesMainThyroid,
  guides_stomach: URL_PATH.GuidesStomach,
}
type Pathname = keyof typeof REDIRECT_URL

export default function NotFound() {
  const { back, replace } = useRouter()

  if (typeof window === 'undefined') return <div />

  const handleClick = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const path = searchParams.get('path') ?? ''
    if (REDIRECT_URL[path as Pathname]) {
      replace(REDIRECT_URL[path as Pathname])
      return
    }
    back()
  }

  return (
    <>
      <NextSeo title={META_TITLES.ERROR} />

      <SubPageHeader title="루닛케어" />
      <div className="w-full" style={{ height }}>
        <div className="w-full h-full flex items-center justify-center text-center">
          <div className="px-md">
            <h2 className="font-bold text-blue-100 text-[64px] mb-1">404</h2>
            <div className="mb-2.5">
              <strong className="text-title">{ERROR['404'].title}</strong>
            </div>
            <div className="mb-5">
              <div className="whitespace-pre-wrap px-4 text-center">
                {ERROR['404'].content}
              </div>
            </div>
            <button
              type="button"
              className="w-full p-2.5 rounded-sm bg-gray-20"
              onClick={handleClick}
            >
              <span className="text-blue-100">이전 페이지로 돌아가기</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
